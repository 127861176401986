/* This file exists to tell Browserify what to package into the carliModules for the CARLI staff app. */

module.exports = {
    // browserClientVersion: require('package.json').version,
    middlewareVersion: require('../middleware/browserAdapters/version').getVersion(),
    ActivityLog: require('../CARLI/Entity/ActivityLogRepository'),
    Attachments: require('../CARLI/Attachments'),
    Auth: require('../CARLI/Auth'),
    AuthMiddleware: require('../middleware/browserAdapters/auth'),
    Banner: require('../CARLI/Banner'),
    CouchDbStore: require('../CARLI/Store/CouchDb/Store'),
    config: require('../middleware/browserAdapters/config'),
    Csv: require('../CARLI/Csv'),
    Cycle: require('../CARLI/Entity/CycleRepository'),
    CycleCreationJob: require('../CARLI/Entity/CycleCreationJobRepository'),
    CycleMiddleware: require('../middleware/browserAdapters/cycleCreation'),
    DatabaseStatusMiddleware: require('../middleware/browserAdapters/vendorDatabases'),
    EmailMiddleware: require('../middleware/browserAdapters/email'),
    Library: require('../CARLI/Entity/LibraryRepository'),
    LibraryMiddleware: require('../middleware/browserAdapters/libraryQueries'),
    LibraryStatus: require('../CARLI/Entity/LibraryStatusRepository'),
    License: require('../CARLI/Entity/LicenseRepository'),
    MemoryStore: require('../CARLI/Store/MemoryStore'),
    Membership: require('../CARLI/Entity/MembershipRepository'),
    Notification: require('../CARLI/Entity/NotificationRepository'),
    NotificationDraftGenerator: require('../CARLI/NotificationDraftGenerator'),
    NotificationTemplate: require('../CARLI/Entity/NotificationTemplateRepository'),
    Offering: require('../CARLI/Entity/OfferingRepository'),
    Product: require('../CARLI/Entity/ProductRepository'),
    ReportsData: require('../middleware/browserAdapters/reports'),
    Store: require('../CARLI/Store'),
    User: require('../CARLI/Entity/UserRepository'),
    UserMiddleware: require('../middleware/browserAdapters/user'),
    Validator: require('../CARLI/Validator'),
    Vendor: require('../CARLI/Entity/VendorRepository'),
    VendorDatabaseMiddleware: require('../middleware/browserAdapters/vendorDatabases.js'),
    VendorStatus: require('../CARLI/Entity/VendorStatusRepository')
};
